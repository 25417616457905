import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Nunito_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"nunitoSans\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/predoc-sdk/dist/index.css");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/app/src/auth/client-auth-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PHProvider"] */ "/app/src/providers/PostHogProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/index.scss");
